:root {
    --doc-height: 100vh;
    --main-black: #0e0e0e;
    --main-green: #8ebf21;
    --main-white: #fafafa;
    --main-grey: #5C5953;
    --doc-height: 100vh;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: system-ui;
    background-color: var(--main-white);
}

p {
    letter-spacing: 0.012px;
    word-spacing: 0.016px;
    margin-bottom: 2em;
}

.hoverMapsPicture, .hoverExpertisePicture2 {
    height: 60%;
    border: solid 20px rgba(92 89 83 / 0.2);
    width: 100%;
    position: absolute;
    top: 20%;
    left: 20%;
    width: 80%;
    box-sizing: border-box;
}

.hoverExpertisePicture {
    border: solid 10px rgba(142 191 33 / 0.3);
    width: 100%;
    position: absolute;
    bottom: 25%;
    right: 20%;
    width: 80%;
    box-sizing: border-box;
}

.hoverExpertisePicture2 {
    width: 60%;
    left: 40%;
}

.fd {
    background-image: url(./assets/fd/fd.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.slider img {
    object-fit: cover;
    width: 100%;
    height: 100vh;
}

.realisations img {
    margin: auto;
    object-fit: cover;
    border-radius: 12px;
}

.realisationCardTitle {
    width: 100%;
    margin: 0;
    padding: 1rem;
    background-color: rgb(142 191 33 / 0.5);
    color: var(--main-white);
    font-weight: 500;
    font-size: 18px;
    position: absolute;
    width: 91%;
    border-radius: 0 0 2px 2px;
    bottom: 0;
}

.separateRealisationsCards {
    background-image: linear-gradient(to right, var(--main-white), var(--main-green), var(--main-green), var(--main-white));
    border: none;
    height: 2.5px;
    width: 80%;
    border-radius: 4px;
}

.react-photo-gallery--gallery img {
    object-fit: cover;
}

/* .react-photo-gallery--gallery {
    max-height: var(--doc-height);
    overflow-y: scroll;
} */

.cgu {
    margin-top: 10vh;
    padding: 0 100px;
    font-family: Arial;
}

.cgu h2 {
    text-align: center;
    color: var(--main-green);
}

.cgu h3 {
    margin-top: 50px;
    color: var(--main-green);
}

.cgu a {
    color: #04132A;
    text-decoration: none;
}

.cgu a:hover {
    color: #0c7cff;
}

.slider svg {
    display: block;
    position: absolute;
    top: calc(var(--doc-height) * 0.45);
    font-size: 5rem;
    z-index: 5;
    cursor: pointer;
    color:rgba(15 15 15 / 0.7)
}

.realisations svg {
    display: block;
    position: absolute;
    top: 50%;
    font-size: 5rem;
    z-index: 5;
    cursor: pointer;
    color:rgba(142 191 33 / 0.7)
}

.realisations .slick-track {
    display: flex;
    align-items: center;
}

.slider .slick-slider {
    position: initial !important;
}

.slider .slick-dots {
    bottom: 5vh !important;
}

.slider .slick-dots li button:before, .slider .slick-dots li.slick-active button:before {
    font-size: 1rem !important;
    color: var(--main-white) !important;
    transition: all .3s ease-in-out;
}

@supports (-webkit-touch-callout: none) {
    body, .fd {
        background-attachment: scroll;
    }
}

.selectedMenu {
    border-bottom: 2px solid var(--main-green);
    padding-bottom: 5px;
}

.homePres {
    background-color: rgba(15 15 15 / 0.5);
    padding: 3rem 10rem;
}

.homePres h2 {
    color: var(--main-white);
    margin: 0;
    font-weight: 400;
    font-size: 48px;
}

.homePres hr {
    border: none;
    background-color: var(--main-green);
    width: 10rem;
    height: 2px;
}

.homePres h1 {
    margin: 0;
    color: var(--main-white);
    font-weight: 400;
    font-size: 1.17em;
}

#animatedTitleText {
    color: #f7f7f7;
    font-size: 6rem;
    letter-spacing: 0.05em;
    border-right: 0.15em solid #0c7cff;
    animation: blink 0.75s step-end infinite;
}

#animatedTitleText:nth-child(11), #animatedTitleText:nth-child(12), #animatedTitleText:nth-child(13), #animatedTitleText:nth-child(14), #animatedTitleText:nth-child(22), #animatedTitleText:nth-child(23),
#animatedTitleText:nth-child(24), #animatedTitleText:nth-child(25), #animatedTitleText:nth-child(26), #animatedTitleText:nth-child(27) {
    color: #0c7cff;
}

.follow-label {
    padding-right: 75px;
    position: absolute;
    bottom: 100%;
    right: 1.4vh;
    color: #f7f7f7;
    font-size: 16px;
    line-height: 24px;
    height: 24px;
    white-space: nowrap;
    transform-origin: 100% 0;
    text-align: right;
    transform: rotate(90deg);
}

.realisationSection svg {
    font-size: 5rem;
    color: var(--main-green);
    /* position: absolute; */
    /* top: 50%; */
    width: 100%;
    cursor: pointer;
}

.follow-label:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 50px;
    height: 1px;
    background: #FFF;
}

h2 {
    font-weight: 400;
    font-size: 2.5rem;
}

#section2 p, #section4 p {
    font-size: 20px;
    word-spacing: 2px;
}

#section4 p {
    padding: 0;
}

.blackBackground {
    background: url(./assets/separate.webp);
    color: var(--main-white);
}

#section3 svg {
    font-size: 3.5rem;
}

#section3 h3 {
    font-weight: 400;
    font-size: 24px;
    margin-top: 0.5rem;
    margin-bottom: 0;
}

#section3 hr {
    width: 5rem;
    float: left;
}

#section4 h4 {
    font-weight: 400;
    font-size: 20px;
}

#section4 hr:not(.bottomLine, .bottomLine2) {
    width: 60%;
    float: left;
    height: 3px;
    background-color: var(--main-green);
    border: none;
}
.bottomLine, .bottomLine2 {
    width: 3px;
    border: none;
    background-color: var(--main-black);
    opacity: 5%;
    position: absolute;
    top: -6vh;
    z-index: -1;
}

.bottomLine {
    left: 26.65%;
    height: 60%;
}

.bottomLine2 {
    left: 60.15%;
    height: 100%;
}

.subtitle {
    color: var(--main-black);
    text-align: center;
    transition: all .45s ease-in-out;
    margin-bottom: 2rem;
}

.subtitle h2 {
    font-size: 2.5rem;
}

.subtitle h3 {
    font-size: 1.5rem;
    margin: 1rem 0;
    color: var(--main-black);
    font-weight: 400;
}

h4 {
    margin: 1em 0;
    font-size: 1.17em;
}

.subtitle hr {
    width: 10%;
    height: 2.5px;
    border: none;
    background: #0c7cff;
}

.verticalySkills {
    margin-top: 0;
    background-color: transparent;
    padding: 4rem 5vh;
    text-align: center;
    color: #fff;
    padding: 0 5vh;
    font-weight: 600;
}

.verticalySkills img {
    position: relative;
    max-width: 100%;
    max-height: 22rem;
}

.serviceCardContent {
    text-align: center;
    position: relative;
}

.homeFollowIcons {
    position: absolute;
    right: 5vh;
    bottom: 1rem;
}

.expertiseCard {
    padding-top: 1rem;
    border-radius: 2px;
    background: var(--main-white);
    margin-top: 1rem;
    /* box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1); */
    text-align: center;
    border: solid 2px var(--main-green);
    transition: all .3s ease-in-out;
}

.h2Skills {
    text-align: center;
    margin-top: 0;
}

#footer a {
    color: #fff;
}

#footer p {
    margin: 0.5rem 0;
}

#footer h3 {
    margin: 0.8rem 0;
}

#footer {
    padding: 5vh 0;
    margin-top: 5rem;
    color: #fff;
    background: url('./assets/footer.webp');
    transition: all .3s ease-in-out;
}

.homeDescription {
    position: absolute;
    left: 10vh;
    bottom: 4rem;
    color: #f7f7f7;
    font-size: 20px;
    font-weight: 400;
    width: 40rem;
    text-align: left;
}

.homeDescription h1 {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
}

.serviceTitle {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.334;
}

.contactForm {
    margin-top: 3.5rem;
    padding: 0 10vh;
}

.contactItem {
    margin-bottom: 40px;
}

.contactButton {
    border-radius: 2px;
    padding: 15px 30px;
    background-color: var(--main-black);
}

.contactButton:hover {
    background-color: var(--main-green);
}

.form-message {
    visibility: hidden;
    text-align: center;
    color: #111936;
    margin-top: 35px;
}

.parentLogo {
    margin-bottom: 1.5rem;
    text-align: center;
    position: relative;
}

.profilePicture {
    width: 42%;
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.logoBanner {
    height: 2rem;
    width: auto;
    padding: 1.5rem;
    color: #0c7cff;
    border-radius: 50%;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    margin:auto;
    animation: skillUp 3s ease-out infinite;
}

.nbLogo {
    z-index: 1;
    color:#fff;
    background-color: #0c7cff;
    width: 2rem;
    height: 1.7rem;
    padding-top: 0.3rem;
    border-radius: 50%;
    position: absolute;
    margin-top: 1.5rem;
    top: 0rem;
    left: 59%;
    font-size: 16px;
    font-weight: bold;
    animation: skillUp 3s ease-out infinite;
}

section {
    width: 100%;
}

#h2TextBanner {
    font-size: 1.7rem;
}

#menu ul {
    list-style: none;
    margin: 0;
    line-height: 1.5rem;
    padding: 0;
}

.figure-recentWorks {
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    margin: 0;
    padding: 0;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.figure-recentWorks:hover {
    transform: scale(1.1);
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.285);
}

.figure-recentWorks:hover .figCaptionCard, .figure-recentWorks:hover .overlay {
    opacity: 1;
}

.primaryLink {
    color: var(--main-black);
    font-Size: 18px;
    padding: 8px 12px;
    display: block;
    transition: all .2s ease-in-out;
    cursor: pointer;
}

.primaryButton {
    border: 2px solid #0c7cff;
    font-weight: 400;
    font-size: 18px;
    padding: 1.2rem;
    color: #f7f7f7;
    border-radius: 290486px;
    transition: all .2s ease-in-out;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    text-transform: none;
    line-height: 0;
    cursor: pointer;
    width: max-content;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: #0c7cff;
    box-shadow: none;
}

.primaryLink:hover, .primaryButton:hover {
    transform: scale(1.05);
}

.figCaptionCard {
    padding: 0rem 2rem;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    color: #fff;
    z-index: 1;
    opacity: 0;
    transition: .45s ease-in-out;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    opacity: 0;
}

.overlay {
    background-color: #111936;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: .45s ease-in-out;
}

.logo-recentWorks {
    transform: scale(1.1);
    transition: .25s ease-in-out;
    height: 100%;
    width: 100%;
}

#app-logo {
  height: 2.5rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

#app-logo:hover {
    transform: scale(1.05);
}

.logosParentDiv {
    cursor: pointer;
    padding: 15px;
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 100%;
    background-color: #0c7cff;
    transition: all 0.3s ease-in-out;
}

.logos {
    height: 1.2rem;
}

.logosParentDiv:hover {
    transform: scale(1.1) translate3d(0,-5px,0);
    box-shadow: 0 1rem 3rem rgba(35,38,45,.275);
}

.containerTitle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Carto */

#map {
    width: 100%;
    height: 100%;
}

.ol-zoom {
    width: 25px;
    top: 5px;
    left: 5px;
    position: absolute;
}

.ol-zoom-in, .ol-zoom-out {
    background-color: #0c7cff;
    padding: 4px 8px;
    border-style: none;
    color: white;
    border-radius: 3px;
    margin-bottom: 3px;
    cursor: pointer;
}

.ol-rotate-reset, .ol-attribution button, .ol-scale-line {
    display: none;
}

.ol-attribution {
    font-size: 10px;
    position: absolute;
    bottom: 0;
    right: 0;
    color: #111936;
}

.ol-layer canvas {
    border-radius: 10px;
}

/* Arrow */

.arrows {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    bottom: 4vh;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.arrows:hover {
    transform: scale(1.1);
}

.chevron {
    margin-top: -4.5rem;
    position: absolute;
    color: white;
    transform: scale(0.3);
    animation: move-chevron 3s ease-out infinite;
}

.chevron:first-child {
    animation: move-chevron 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
    animation: move-chevron 3s ease-out 2s infinite;
}

@keyframes move-chevron {
    25% {
        opacity: 1;
    }
    33.3% {
        opacity: 1;
        transform: translateY(2.28rem);
    }
    66.6% {
        opacity: 1;
        transform: translateY(3.12rem);
    }
    100% {
        opacity: 0;
        transform: translateY(4.8rem) scale(0.5);
    }
}

#scrollUp {
    position: fixed;
    bottom : 10px;
    right: -100px;
    transition: all 0.45s ease-in-out;
    cursor: pointer;
    z-index: 999;
}

#scrollUp img {
    color: #0c7cff;
    height: 2.5rem;
    width: auto;
}

#scrollUp img:hover {
    transform: scale(1.2);
    transition: all 0.2s ease-in-out;
}

.logoCard {
    height: 5rem;
    width: auto;
}

.logoServices {
    height: 2rem;
    transition: all 0.3s ease-in-out;
}

.cardLanguage {
    border-radius: 12px;
    color: white;
    text-align: center;
    box-shadow: 0 2rem 3rem rgba(35,38,45,.275);
    cursor: pointer;
    background: #111936;
}

.cardLanguage:hover {
    transform: scale(1.05);
    transition: .30s ease-in-out;
}

#menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 15px 8vh;
    z-index: 15;
    background: #fafafa;
    transition: all .5s ease-in-out;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

#accueil {
    position: relative;
    width: 100%;
    height: 100vh;
    height: var(--doc-height);
    text-align: center;
    z-index: 1;
    animation: bg-breathe 15s ease infinite;
    overflow: hidden;
}

@keyframes arrows {
    0% {
        border-left:80/3 solid rgba(0,0,0,0);
        border-bottom:80/3 solid rgba(0,0,0,0);
        transform:translate(80/-6,80*4/-6) rotate(-45deg);
    }
    10%, 90% {
        border-left:80/3 solid rgba(0,0,0,0);
        border-bottom:80/3 solid rgba(0,0,0,0);
    }
    50% {
        border-left:80/3 solid rgba(0,0,0,0.7);
        border-bottom:80/3 solid rgba(0,0,0,0.7);
        transform:translate(80/-6,0px) rotate(-45deg) ;
    }
    100% {
        border-left:80/3 solid rgba(0,0,0,0);
        border-bottom:80/3 solid rgba(0,0,0,0);
        transform:translate(80/-6,80*4/6) rotate(-45deg) ;
    }
}

/**
* Mobile menu
*/
  #menuToggle {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 2px;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
    transition: all 0.3s ease-in-out;
  }

  #menuToggle input
  {
    display: flex;
    width: 40px;
    height: 32px;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
  }

  #menuToggle span {
    display: flex;
    width: 29px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    background: var(--main-black);
    border-radius: 3px;
    z-index: 1;
    transform-origin: 5px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease;
  }

  #menuToggle span:first-child
  {
    transform-origin: 0% 0%;
  }

  #menuToggle span:nth-last-child(2)
  {
    transform-origin: 0% 100%;
  }

  #menuToggle input:checked ~ span
  {
    opacity: 1;
    transform: rotate(45deg) translate(-3px, -1px);
    background: var(--main-black);
  }
  #menuToggle input:checked ~ span:nth-last-child(3)
  {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  #menuToggle input:checked ~ span:nth-last-child(2)
  {
    transform: rotate(-45deg) translate(0, -1px);
  }

  #menuHidden
  {
    position: absolute;
    width: 100vh;
    height: 100vh;
    left: -35vh;
    box-shadow: 0 0 10px #85888C;
    margin: -50px 0 0 0;
    padding: 50px;
    padding-top: 125px;
    background-color: var(--main-black);
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(100%, 0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  }

  #menuHidden li
  {
    padding: 10px 0;
    transition-delay: 2s;
  }

  #menuHidden2 {
    position: fixed;
    display: flex;
    width: 100%;
    height: auto;
    justify-content: space-between;
    align-items: center;
    left: 0rem;
    bottom: 0rem;
    border-radius: 0;
    box-shadow: 0 0 6px #85888C;
    padding: 0.75rem 0 1.25rem !important;
    background-color: var(--main-white);
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(0%, 30vh);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  }

  #menuHidden2 li
  {
    width: 33.33333%;
    text-align: center;
    transition-delay: 2s;
  }

  #menuHidden2 div {
      border-radius: 10px;
  }

  #menuHidden2 img {
    height: 2.9rem;
  }

  #menuHidden2 p {
      font-size: 14px;
      font-weight: 400;
      margin: 0;
      text-align: center;
  }

  #menuToggle input:checked ~ ul
  {
    transform: none;
  }

@keyframes blink {
    from,
    to {
        border-color: transparent;
    }
    50% {
        border-color: #0c7cff;
    }
}

@keyframes skillUp {
	0% {
	    transform: translateY(0);
	    animation-timing-function: ease-in;
	}
	50% {
	    transform: translateY(-10px);
	    animation-timing-function: ease-out;
	}
    100% {
	    transform: translateY(0);
	    animation-timing-function: ease-in;
	}
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }
    13% {
        transform: translateY(-20px);
    }
    29% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(0);
    }
}

@media screen and (max-width: 1450px) {

    #section3 {
        padding: 5vh 0;
    }

    #section4 {
        padding: 0;
    }

}

@media screen and (max-width: 1030px) {

    #section4 {
        padding: 0;
    }

    .expertiseCard h4 {
        margin: 0;
        margin-bottom: 0.8rem;
    }

}

@media screen and (max-width: 780px) {

    #section2 p {
        padding: 2rem 0;
    }

    #app-logo {
        height: 2rem;
    }

}

@media screen and (max-width: 500px) {

    .homePres {
        padding: 1rem 2rem;
    }

    .containerTitle {
        width: 80%;
    }

    .homePres h2 {
        font-size: 36px;
    }

    .slider svg {
        font-size: 3.5rem;
        top: 46.5%;
    }

    .contactForm {
        padding: 0 1vh;
    }

    #menu {
        padding: 1rem;
    }

    .cgu {
        padding: 0 25px;
    }

    .cgu h2 {
        font-size: 2rem;
    }

}

@media screen and (max-width: 330px) {

    .cgu h2 {
        font-size: 1.5rem;
    }

}
